<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import Textfield from "@smui/textfield";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  const dispatch = createEventDispatcher();

  /** ダイアログの開閉フラグ @type {boolean} */
  export let open;
  /** 送り状番号 @type {string} */
  export let trackingNumber;
  /** 配送状況の問合せ結果 @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;
  /** 認証成功後に実行する関数 @type {Function} */
  export let functionAfterIdentification = null;

  /** @type {string} 荷受人郵便番号 */
  let postcode = sessionStorage.getItem("identificationPostcode") ?? "";
  /** @type {string} 荷受人電話番号 */
  let tel = sessionStorage.getItem("identificationTel") ?? "";

  /** 本人確認情報にエラーがあるか否か（登録されている配送情報と一致していない） */
  let hasIdentificationError = false;

  /** お届け先情報の確認方法を開いているか否か */
  let isHelpMessageOpen = false;

  /**
   * 本人確認情報付きで配送情報取得APIを呼び出して、表示中の荷物情報を更新する。
   */
  async function trackingWithIdentification() {
    try {
      // 本人確認情報付きで配送情報取得APIを呼び出し
      result = await backendApi.getShipment(trackingNumber, {
        postcode,
        tel,
      });
      console.log(result);

      // 置き配写真URLのパラメータ名変更に対応するため、古いパラメータ名を新しいパラメータ名に変換する
      // 完全に切り替わったら削除してOK
      if (!result.podPhotoUrl) {
        result.podPhotoUrl = result.url;
      }

      // 会社IDが取得できなかった場合は本人確認情報が一致していない
      hasIdentificationError = !Number.isInteger(result.companyId);
      if (hasIdentificationError) {
        isHelpMessageOpen = true;
        return;
      }

      sessionStorage.setItem("identificationPostcode", postcode);
      sessionStorage.setItem("identificationTel", tel);

      if (functionAfterIdentification) {
        await functionAfterIdentification();
        functionAfterIdentification = null;
      }
    } catch (error) {
      console.error(error);
      toast.error($_("errors.failedToApiComm"));
      return;
    }

    dispatch("identify", { result: result });

    // 取得に成功した場合はダイアログを閉じる
    open = false;
  }
</script>

<Dialog
  bind:open
  aria-labelledby="identification-dialog-title"
  aria-describedby="identification-dialog-content"
>
  <Title id="identification-dialog-title"
    >{$_("pages.Tracking.IdentificationDialog.title")}</Title
  >

  <Content id="identification-dialog-content">
    <div class="row">
      <p class="description">
        {$_("pages.Tracking.IdentificationDialog.description")}
      </p>
    </div>

    {#if hasIdentificationError}
      <div class="row alertMessage">
        <span class="material-icons md-24">warning_amber</span>
        <p>
          {$_("pages.Tracking.IdentificationDialog.identificationErrorMessage")}
        </p>
      </div>
    {/if}

    <div class="row">
      <Textfield
        bind:value={postcode}
        variant="outlined"
        label={$_("pages.Tracking.IdentificationDialog.inputPostcodeLabel")}
        required
        input$inputmode="numeric"
        autocomplete="postal-code"
      />
    </div>
    <div class="row">
      <Textfield
        bind:value={tel}
        variant="outlined"
        label={$_("pages.Tracking.IdentificationDialog.inputTelLabel")}
        required
        input$inputmode="numeric"
        autocomplete="tel-national"
      />
    </div>

    {#if isHelpMessageOpen}
      <div class="helpMessage">
        <div>
          <span class="material-icons"> help_outline </span>{$_(
            "pages.Tracking.IdentificationDialog.helpMessageTitle",
          )}
        </div>
        <ul>
          <li>{$_("pages.Tracking.IdentificationDialog.helpExample1")}</li>
          <li>{$_("pages.Tracking.IdentificationDialog.helpExample2")}</li>
        </ul>
      </div>
    {:else}
      <div class="helpMessageButtonArea">
        <button
          class="helpMessageOpenButton"
          on:click={() => (isHelpMessageOpen = true)}
        >
          <span class="material-icons"> help_outline </span><span
            class="helpTitle"
            >{$_("pages.Tracking.IdentificationDialog.helpMessageTitle")}</span
          >
        </button>
      </div>
    {/if}
  </Content>

  <Actions>
    <Button
      on:click={(event) => {
        event.preventDefault();
        loadingProgress.wrapAsync(trackingWithIdentification)();
      }}
      disabled={!postcode || !tel}
      action=""
    >
      <Label>{$_("pages.Tracking.IdentificationDialog.okButtonLabel")}</Label>
    </Button>
    <Button on:click={() => (open = false)}>
      <Label
        >{$_("pages.Tracking.IdentificationDialog.cancelButtonLabel")}</Label
      >
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  .row {
    max-width: 320px;
    box-sizing: border-box;

    .description {
      text-align: left;
      line-height: 1.3;
    }

    :global(.mdc-text-field) {
      width: 100%;
      margin-top: 15px;
    }
  }

  .alertMessage {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 14px;
    margin-top: 10px;
    padding: 12px;
    background-color: #ffe7e7;
    border-radius: 4px;
    text-align: left;
    color: #672b2a;

    p {
      line-height: 1.4;
      font-size: 0.9rem;
    }
  }

  .helpMessageButtonArea {
    width: 100%;
    max-width: 320px;
    box-sizing: border-box;
    margin-top: 10px;
    display: grid;
    justify-items: end;

    .helpMessageOpenButton {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      color: #333;
      background-color: #fff;
      border: none;
      cursor: pointer;

      .material-icons {
        font-size: 20px;
      }

      .helpTitle {
        text-decoration: underline;
      }
    }
  }

  .helpMessage {
    max-width: 320px;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 12px;
    background-color: #f0f0f0;
    border-radius: 4px;
    text-align: left;
    color: #333;

    .material-icons {
      font-size: 20px;
      margin-right: 3px;
    }

    div {
      display: flex;
      align-items: center;
      gap: 2px;
      font-weight: bold;
      color: #5f5f5f;
    }

    ul {
      font-size: 14px;
      margin-top: 4px;
      padding-left: 20px;
      list-style-type: disc;
    }
  }
</style>
